<template>
    <div class="container">
        <div style="position:relative;height:60px;background-color: #136AFB">
            <div class="toptitle">我的报名列表</div>
            <img :src="require('../../public/back.png')"  class="back" @click="$router.back()"/>
        </div>
        <div>
            <div class="topline" @click="toHome">
            <img :src="require('../../public/user.png')" style="width:20px"/>
            <span class="username">{{user_name}}</span>
            </div>
        </div>
        <div v-if="applications.length===0" class="noInfo">暂无报名信息~</div>
        <div class="application" v-for="item in applications" :key="item._id.$id" @click="toApplication(item)">
            <div class="firstTab">{{item.project.project_name}}</div>
            <div class="detail">
            <div style="width:50%">
                <div class="tip">科目</div>
                <div class="tipcontent">{{item.application_events[0]}}</div>
            </div>
<!--            <div style="width:50%">-->
<!--                <div class="tip">考场</div>-->
<!--                <div class="tipcontent">{{item.test_centers[0].test_center_name}}</div>-->
<!--            </div>-->
            </div>
            <div class="detail">
<!--            <div style="width:50%">-->
<!--                <div class="tip">时间</div>-->
<!--                <div class="tipcontent">{{item.date_name}}</div>-->
<!--            </div>-->
            <div style="width:50%">
                <div class="tip">缴费状态</div>
                <div class="tipcontent">{{item.application_status}}</div>
            </div>
            </div>
        </div>

      <div class="bottom">
            <div class="btn" @click="add">去报名</div>
        </div>
    </div>
</template>

<script>
import { getApplications } from '../api/test'
import { getUserId, getUserName } from '../utils/store'
export default {
  data () {
    return {
      user_id: '',
      user_name: '',
      applications: []
    }
  },
  created () {
    this.user_id = getUserId()
    this.user_name = getUserName()
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData () {
      getApplications(this.user_id).then(res => {
        if (res.data.code === 0) {
          const applications = res.data.data
          for (const item of applications) {
            item.date_name = this.formate(item)
          }
          this.applications = applications
        }
      })
    },
    toApplication (data) {
      this.$router.push({
        path: '/application',
        query: {
          application_id: data._id.$id
        }
      })
    },
    add () {
      this.$router.push('/apply')
    },
    formate (arr) {
      return ''
      // const date = new Date(arr.project_event.start_time * 1000)
      // const start_time = new Date(arr.project_event.start_time * 1000)
      // const end_time = new Date(arr.project_event.end_time * 1000)

      // return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日 ${start_time.getHours()}:${start_time.getMinutes() < 10 ? '0' + start_time.getMinutes() : start_time.getMinutes()}-${end_time.getHours()}:${end_time.getMinutes() < 10 ? '0' + end_time.getMinutes() : end_time.getMinutes()}`
    },
    toHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.container{
  width: 100vw;
  height: 100vh;
  background: #FBF8F8;
  overflow-y:scroll;
  position: relative;
}
.toptitle{
  position: absolute;
  width: 100%;
  z-index: 999;
  font-size: 18px;
  text-align: center;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 60px;
  color: #FFFFFF;
  opacity: 1;
}
.back{
  position: absolute;
  z-index: 999;
  left: 20px;
  top:50%;
  transform: translateY(-50%);
  width: 18px;
}
.application{
  cursor: pointer;
  width: 86%;
  margin:16px auto;
  height: 146px;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  padding: 15px;
}
.detail{
  display: flex;
  margin-top: 14px;
}
.firstTab{
  padding:4px;
  border-bottom: 2px solid rgba(66, 133, 244, 0.2);
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 22px;
  color: #4285F4;
}
.tip{
  font-size: 10px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 14px;
  color: #333333;
}
.tipcontent{
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 17px;
  color: #333333;
  margin-top: 6px;
}
.bottom{
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn{
  cursor: pointer;
  width: 90%;
  background: #4285F4;
  height: 40px;
  border: 1px solid #4285F4;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 20px;
  color: #FFFFFF;
  line-height: 40px;
  text-align: center;
}
.username{
  font-size: 14px;
  margin-left: 4px;
  font-family: PingFang SC;
  font-weight: 800;
  line-height: 24px;
  color: #4285F4;
}
.topline{
  height: 36px;
  background: #DBE9FF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noInfo{
    font-family: PingFang SC;
    color: #909399;
    text-align: center;
    line-height: 60px;
}
</style>
