<template>
    <div style="background: #FBF8F8;">
        <div style="position:relative;height:60px;background-color: #136AFB">
            <div class="toptitle">科目列表</div>
            <img :src="require('../../public/back.png')"  class="back" @click="$router.back()"/>
        </div>
        <div>
            <div class="topline" @click="toHome">
            <img :src="require('../../public/user.png')" style="width:20px"/>
            <span class="username">{{user_name}}</span>
            </div>
        </div>
        <el-tabs v-model="activeName" type="card" style="padding-top:0">
            <el-tab-pane label="中文科目">
                <div class="event" v-for="item in cs_events" :key="item._id.$id" @click="toEvent(item._id.$id)">
                    <div class="firstTab">{{item.name}}</div>
                    <div class="eventIntro">{{item.intro}}</div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="英文科目">
                <div class="event" v-for="item in en_events" :key="item._id.$id" @click="toEvent(item._id.$id)">
                    <div class="firstTab">{{item.name}}</div>
                    <div class="eventIntro">{{item.intro}}</div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getUserName } from '../utils/store'
import { getEvents } from '../api/test'
import vPinyin from '../utils/pinyin.js'
export default {
  data () {
    return {
      user_name: '',
      cs_events: [],
      en_events: [],
      height: '',
      activeName: ''
    }
  },
  created () {
    this.height = document.documentElement.clientHeight
    this.user_name = getUserName()
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData () {
      getEvents('中文').then(res => {
        if (res.data.code === 0) {
          this.cs_events = this.resortCs(res.data.data)
        }
      })
      getEvents('英文').then(res => {
        if (res.data.code === 0) {
          this.en_events = this.resortEn(res.data.data)
        }
      })
    },
    toHome () {
      this.$router.push('/')
    },
    toEvent (id) {
      this.$router.push({
        path: '/event',
        query: {
          event_id: id
        }
      })
    },
    resortEn (arr) {
      arr.sort((a, b) => {
        return (a.name).localeCompare(b.name)
      })
      return arr
    },
    resortCs (arr) {
      arr.sort((a, b) => {
        return vPinyin.chineseToPinYin(a.name).localeCompare(vPinyin.chineseToPinYin(b.name))
      })
      return arr
    }
  }
}
</script>

<style>
.event{
  cursor: pointer;
  width: 86%;
  margin:16px auto;
  background: #FFFFFF;
  opacity: 1;
  border-radius: 4px;
  padding: 15px;
}
.eventIntro{
    margin-top: 4px;
    padding: 4px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 17px;
    color: #333333;
}
/* .content{
    overflow-y: scroll;
} */
.el-tabs__item.is-active{
    color: #4285F4!important;
    font-weight: bold;
}
.el-tabs__item{
    padding: 0 20px!important;
    height: 36px!important;
    line-height: 36px!important;
}
.el-tabs__header{
    margin: 0!important;
}
</style>
