<template>
  <div class="container">
    <div style="height: 60px;background-color: #136AFB;text-align: center">
      <p style="margin: 0;padding: 0;color: white;font-size: 20px;line-height: 60px">提示</p>
    </div>
    <div style="margin: 20px">
      提示界面
    </div>
  </div>
</template>
<script>
export default {
  name: 'main_register'
}
</script>

<style scoped>

.container {
  height: 100vh;
  background-color: white;
}

</style>
