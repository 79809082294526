<template>
    <div>
        <div style="position:relative;height:60px;background-color: #136AFB">
          <div class="toptitle">科目详情</div>
          <img :src="require('../../public/back.png')"  class="back" @click="$router.back()"/>
        </div>
        <div class="topline" @click="toHome">
            <img :src="require('../../public/user.png')" style="width:20px"/>
            <span class="username">{{user_name}}</span>
        </div>
        <div class="maincontent" v-loading="loading">
            <div class="applicationtxt">科目名称</div>
            <div class="paramtxt">{{event.name}}</div>
            <div class="applicationtxt">科目类别</div>
            <div class="paramtxt">{{event.category}}</div>
            <!-- <div class="applicationtxt">科目级别</div>
            <div class="paramtxt">{{event.level}}</div> -->
            <div class="applicationtxt">评测费用</div>
            <div class="paramtxt">{{event.event_price}}</div>
            <div class="applicationtxt">评测语言</div>
            <div class="paramtxt">{{event.language}}</div>
            <div class="applicationtxt">适合人群</div>
            <div class="paramtxt" v-html="event.suit"></div>
            <div class="applicationtxt">评测内容</div>
            <div class="paramtxt" v-html="event.content"></div>
            <div class="applicationtxt">评测目标</div>
            <div class="paramtxt" v-html="event.target"></div>
            <!-- <div class="applicationtxt">学习前景</div>
            <div class="paramtxt" v-html="event.prospect"></div> -->
            <div class="applicationtxt">建议备考时间</div>
            <div class="paramtxt">{{event.prepare_time}}</div>
            <div class="applicationtxt">标签</div>
            <div class="paramtxt">
                <el-tag type="primary" v-for="item in event.label" size="medium" :key="item" style="margin-right:6px;background:white;color:#4285F4;border:1px solid #4285F4">{{item}}</el-tag>
                <!-- <span v-for="item in event.label" :key="item" style="margin-right:6px;color:#8F0001;font-size:14px;font-weight:bold">{{item}}</span> -->
            </div>
            <div class="applicationtxt">科目简介</div>
            <div class="paramtxt">{{event.intro}}</div>
            <div class="applicationtxt">报名须知</div>
            <div class="paramtxt" style="padding:0;border-bottom:none">
                <ul style="padding-inline-start:20px">
                    <li>请在填报报名信息前仔细阅读本须知。测试者若对本须知内的任何事项有疑惑可随时联系我们。若测试者对于须知内的任何事项不予认可，应立刻终止报名流程。若测试者完成报名后，即默认测试者对于本须知内的所有事项均予以同意。</li>
                    <li>测试者须在规定的时间内通过微信服务号“EnTA服务中心”完成报考，其中包括信息完善、信息核对及网上缴费等操作。</li>
                    <li>测试者报考前须在微信服务号“EnTA服务中心”按照要求进行手机号登录。</li>
                    <li>登录成功后，测试者须按照系统要求填写姓名、身份证号、邮箱完善个人信息。姓名、身份证号将作为测试者身份证明与身份审核使用；邮箱将作为测试者接收官方消息提醒使用。测试者需仔细核对确认填写信息的正确性，若由于测试者填写信息错误导致的一切不利后果均与EnTA无关。</li>
                    <li>测试者的身份和评测成绩信息除供相关企业雇主查询外，未经测试者授权，EnTA不会向第三方公开测试者的身份信息和评测成绩信息。</li>
                    <li>报名时，EnTA将对测试者提交的姓名、手机号和身份证号三者进行审核以验证测试者身份。三项身份信息必须真实有效，手机号须为测试者本人实名登记的号码。提交虚假信息的行为将导致无法报名和参加评测。</li>
                    <li>测试者同一季度不可同时报考两门及以上科目。</li>
                    <li>报名信息提交前测试者须对个人信息和报名信息进行核对，核对无误后再进行提交。姓名和身份证号提交后不得更改；如需修改手机号和邮箱地址，请在个人中心进行修改；手机号修改需重新进行手机号验证和测试者身份验证。如需修改评测科目和评测时间，请在报名截止日前在【个人中心】进行修改。</li>
                    <li>报名成功后，测试者即可在微信服务号“EnTA服务中心”【个人中心】查看和下载准考证，并根据指导登陆 EnTA学习系统进行评测知识学习和题目练习。</li>
                    <li>测试者须仔细查看报名科目的评测时间和评测地点，在规定时间内到达评测地点并参加评测。</li>
                    <li>测试者可在评测结束后10个工作日后登陆微信服务号“EnTA服务中心”查看评测成绩，在【个人中心】下载成绩单和证书。</li>
                    <li>如有问题，请通过邮件进行咨询，邮件地址：contact@enta.center。</li>

                </ul>
            </div>
            <div class="checkbox">
                <el-checkbox v-model="check">我已阅读报名须知，并承诺遵守须知相应规范</el-checkbox>
            </div>
        </div>
        <div class="paybtn">
          <div class="paybtminner" @click="toApply">去报名</div>
        </div>
    </div>
</template>

<script>
import { getEvent } from '../api/test'
import { getUserName } from '../utils/store'
export default {
  data () {
    return {
      event_id: this.$route.query.event_id,
      event: {},
      user_name: '',
      loading: false,
      suit: '',
      check: false
    }
  },
  mounted () {
    this.user_name = getUserName()
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData () {
      getEvent(this.event_id).then(res => {
        if (res.data.code === 0) {
          this.event = res.data.data
          this.event.suit = this.event.suit.replace(/\n/g, '<br>')
          this.event.content = this.event.content.replace(/\n/g, '<br>')
          this.event.target = this.event.target.replace(/\n/g, '<br>')
          this.event.prospect = this.event.prospect.replace(/\n/g, '<br>')
        }
      })
    },
    toHome () {
      this.$router.push('/')
    },
    toApply () {
      if (!this.check) {
        this.$message.error('请先勾选同意报名须知')
        return
      }
      this.$router.push({
        path: '/apply',
        query: {
          event_id: this.event_id
        }
      })
    }
  }
}
</script>

<style>
.checkbox{
    margin-top: 10px;
    font-size: 14px;
    /* display: flex;
    justify-content: center; */
}
.el-checkbox__input.is-checked+.el-checkbox__label{
    color: #4285F4!important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background: #4285F4!important;
    border-color: #4285F4!important;
}

</style>
