<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {}
  },
  created () {

  },
  methods: {}
}
</script>

<style scoped lang="scss">
*{
  padding: 0;
  margin: 0;
}
</style>
