<template>
  <div></div>
</template>

<script>
import { getUserId } from '../../utils/store'
// 入口1： 服务号的主界面
export default {
  name: 'fwh_main',
  mounted () {
    const user_id = getUserId()
    if (user_id) {
      this.$router.replace('/')
      return
    }
    const appId = 'wx8b69314d666b5158'
    const redirectUrl = 'https://fblc-gzh.reachable-edu.com/link?route=main_register'
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1'
  },
  methods: {}
}
</script>

<style scoped>

</style>
