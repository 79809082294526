<template>
    <div>
        {{loginMessage}}
    </div>
</template>

<script>
import { getOpenId, loginOnWebByWechat } from '../api/test'
export default {
  data () {
    return {
      code: this.$route.query.code,
      key: this.$route.query.key,
      loginMessage: ''
    }
  },
  created () {
    if (this.code) {
      getOpenId(this.code).then(res => {
        if (res.data.code === 0) {
          const openid = res.data.data.openid
          this.loginOnWebByWechat(this.key, openid)
        } else {
          this.$message.error('获取用户信息失败')
        }
      })
    } else {
      this.$message.error('登陆失败')
      this.loginMessage = '登陆失败'
    }
  },
  methods: {
    loginOnWebByWechat (key, open_id) {
      loginOnWebByWechat(key, open_id).then(res => {
        if (res.data.code === 0) {
          this.loginMessage = '登陆成功'
        } else {
          this.loginMessage = '登陆失败'
        }
      })
    }
  }
}
</script>

<style>

</style>
