<template>
  <div>
    <div style="position:relative;height:60px;background-color: #136AFB">
      <div class="toptitle">修改信息</div>
      <img :src="require('../../public/back.png')" class="back" @click="$router.back()"/>
    </div>
    <div class="topline" @click="toHome">
      <img :src="require('../../public/user.png')" style="width:20px"/>
      <span class="username">{{ user_name }}</span>
    </div>
    <div class="form">
      <el-form :model="user">
        <el-form-item label="姓氏" prop="student_lastName">
          <el-input v-model="user.student_lastName" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="名字" prop="student_givenName">
          <el-input v-model="user.student_givenName" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓氏拼音" prop="student_lastName_pinyin">
          <el-input v-model="user.student_lastName_pinyin" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="名字拼音" prop="student_givenName_pinyin">
          <el-input v-model="user.student_givenName_pinyin" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="student_gender">
          <el-select v-model="user.student_gender" placeholder="请选择">
            <el-option v-for="item in genders" :key="item" :value="item" :label="item"/>
          </el-select>
        </el-form-item>
        <el-form-item label="年级(预计高中毕业年份)" prop="student_graduation">
          <el-select v-model="user.student_graduation">
            <el-option v-for="item in graduations" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="student_mobile">
          <el-input v-model="user.student_mobile" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="邮箱(将作为账户登录标识)" prop="student_email">
          <el-input v-model="user.student_email" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="监护人手机" prop="student_guardian_first_mobile">
          <el-input v-model="user.student_guardian_first_mobile" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="监护人邮箱" prop="student_guardian_first_email">
          <el-input v-model="user.student_guardian_first_email" placehoder="请输入"></el-input>
        </el-form-item>
      </el-form>

    </div>
    <div>
      <div class="applicationbtn" style="margin-bottom: 20px" @click="updateUserInfo">确认修改</div>
    </div>
  </div>
</template>

<script>
import {getUnionId, getUserName} from '../utils/store'
import { getStudentInfoByUnionid, updateStudentInfo } from '../api/test'

export default {
  data () {
    return {
      user_name: '',
      genders: ['男', '女'],
      graduations: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      user: {},
      paramValue: this.$route.query.value,
      param: this.$route.query.param
    }
  },
  created () {
    this.user_name = getUserName()
    this.getUser()
  },
  methods: {
    getUser () {
      var unionid = getUnionId()
      getStudentInfoByUnionid(unionid).then(res => {
        if (res.data.code === 0) {
          this.user = res.data.data
        }
      })
    },
    updateUserInfo () {
      updateStudentInfo(this.user._id.$id, this.user).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '修改成功',
            type: 'success'
          })
          this.$router.push('/')
        } else {
          this.$message.error('网络错误')
        }
      })
    },
    toHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style>
.input {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 30px;
}
.form{
  width:80%;
  margin:0 auto;
}
</style>
