import Vue from 'vue'
import Router from 'vue-router'

import Login from '../pages/login.vue'
import Apply from '../pages/apply.vue'
import Applications from '../pages/applications.vue'
import Application from '../pages/application.vue'
import Home from '../pages/home.vue'
import Link from '../pages/link.vue'
import webLink from '../pages/weblink.vue'
import setting from '../pages/setting.vue'
import events from '../pages/events.vue'
import event from '../pages/event.vue'
import auth from '../pages/auth.vue'
import fwh_main from '../pages/entry/fwh_main.vue'
import fwh_register from '../pages/entry/fwh_register.vue'
import main_register from '../pages/register/main_register.vue'
import register from '../pages/register/register.vue'

import { getUserId, getUnionId } from '../utils/store'

Vue.use(Router)
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
        title: '个人中心'
      }
    },
    {
      path: '/entry_main',
      name: 'fwh_main',
      component: fwh_main
    },
    {
      path: '/main_register',
      name: 'main_register',
      component: main_register
    },
    {
      path: '/register',
      name: 'register',
      component: register
    },
    {
      path: '/entry_register',
      name: 'fwh_register',
      component: fwh_register
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/apply',
      name: 'apply',
      component: Apply,
      meta: {
        requiresAuth: true,
        title: '科目报名'
      }
    },
    {
      path: '/applications',
      name: 'applications',
      component: Applications,
      meta: {
        requiresAuth: true,
        title: '我的报名表'
      }
    },
    {
      path: '/application',
      name: 'application',
      component: Application,
      meta: {
        requiresAuth: true,
        title: '报名详情'
      }
    },
    {
      path: '/link',
      name: 'link',
      component: Link
    },
    {
      path: '/weblink',
      name: 'weblink',
      component: webLink
    },
    {
      path: '/setting',
      name: 'setting',
      component: setting,
      meta: {
        requiresAuth: true,
        title: '修改信息'
      }
    },
    {
      path: '/events',
      name: 'events',
      component: events,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/event',
      name: 'event',
      component: event,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/auth',
      name: 'auth',
      component: auth,
      meta: {
        requiresAuth: true
      }
    }
  ]
})
router.beforeEach((to, from, next) => {
  // let redirectUrl = encodeURIComponent()
  const userId = getUserId()
  const open_id = getUnionId()
  if (to.meta.requiresAuth && (!userId || !open_id)) {
    next({
      path: '/entry_main'
    })
  } else {
    next()
  }
})
//
// // eslint-disable-next-line no-unused-expressions,no-sequences
// router.afterEach((to, from, next) => {
//   window, scrollTo(0, 0)
// })
export default router
