<template>
    <div>
        <div style="position:relative;height:60px;background-color: #136AFB">
          <div class="toptitle">报名详情</div>
          <img :src="require('../../public/back.png')"  class="back" @click="$router.back()"/>
        </div>
        <div class="topline" @click="toHome">
            <img :src="require('../../public/user.png')" style="width:20px"/>
            <span class="username">{{user_name}}</span>
        </div>
        <div class="maincontent" v-loading="loading">
<!--            <div class="applicationtxt" style="margin-top:0">批次</div>-->
            <div class="paramtxt">{{application.project.project_name}}</div>
            <div class="applicationtxt">科目</div>
            <div class="paramtxt">{{application.application_events[0]}}</div>
<!--            <div class="applicationtxt">日期</div>-->
<!--            <div class="paramtxt">{{// formateDate(application.project_event.start_time)}}</div>-->
<!--            <div class="applicationtxt">时间</div>-->
<!--            <div class="paramtxt">{{formateTime(application.project_event.start_time,application.project_event.end_time)}}</div>-->
<!--            <div class="applicationtxt">考场</div>-->
<!--            <div class="paramtxt">{{application.test_centers[0].test_center_name}}</div>-->
<!--            <div class="applicationtxt">地址</div>-->
<!--            <div class="paramtxt">{{application.test_centers[0].test_center_address}}</div>-->
            <div class="applicationtxt">报名途径</div>
            <div class="paramtxt">{{application.application_channel}}</div>
<!--            <div class="applicationtxt">评测费用</div>-->
<!--            <div class="paramtxt">{{application.events[0].event_price?application.events[0].event_price:application.project.project_price}}</div>-->
            <div class="applicationtxt">缴费状态</div>
            <div class="paramtxt">{{application.application_status}}</div>
            <div class="applicationtxt" v-if="application.application_status==='待缴费'">缴费须知</div>
            <div class="paramtxt" v-if="application.application_status==='待缴费'" style="padding:0;border-bottom:none">
                <ul style="padding-inline-start:20px">
                    <li>测试者需在报名时在微信服务号“EnTA服务中心”通过微信支付的方式完成缴费。</li>
                    <li>所有报名费均不含支付手续费，6‰（千分之六）的微信支付手续费由报名者自行承担；支付手续费将在微信支付页面中自动加总。</li>
                    <li>如因测试者个人原因取消已报名的评测，可在“EnTA服务中心”【个人中心】进行取消。 退费政策如下：
                        <ul style="padding-inline-start:20px">
                            <li>于评测开始前20个自然日（不含）提出退费申请的，退还除微信支付手续费外的全部报名费用；</li>
                            <li>距离评测开始前10（不含）至20（含）个自然日期间提出退费申请的，扣除场地预约费用50元和6‰（千分之六）微信支付手续费后，退还余下款项；</li>
                            <li>距离评测开始前10（含）个自然日内提出退费申请的，所有报名费用和微信支付手续费用概不退还。</li>
                        </ul>
                    </li>
                    <li>如因EnTA 评测中心原因造成测试者无法正常参加评测，退还全部评测费用和微信支付手续费。</li>
                    <li>如因疫情、极端天气等不可抗力因素导致评测无法正常组织，扣除微信支付手续费后，退还全部评测费用。 </li>

                </ul>
            </div>
            <div class="checkbox">
                <el-checkbox v-model="check" v-if="application.application_status==='待缴费'">我已阅读缴费须知，并承诺遵守须知相应规范</el-checkbox>
            </div>
            <div class="applicationtxt" v-if="application.application_status==='已缴费'">考前须知</div>
            <div class="paramtxt" v-if="application.application_status==='已缴费'" style="padding:0">
                <ul style="padding-inline-start:20px">
                    <li>测试者应在考前登录EnTA学习系统（系统网址http://my.enta.center）查看官方资料进行备考。官方备考资料包含评测知识点和练习题。</li>
                    <li>所有学习资料均为EnTA测评中心所有的教研成果，仅供测试者本人作为备考使用。未经EnTA测评中心允许，测试者不得擅自复制、修改、公开和传播相关内容。否则，EnTA测评中心将追究测试者法律责任。</li>
                    <li>测试者应按照准考证上的时间和评测地点按时到达指定考点。</li>
                    <li>疫情防控期间，测试者进入考场前须按照要求配合考点完成体温和健康码检测。</li>
                    <li>评测当日测试者须携带身份证和相应科目的电子准考证。电子准考证请在微信服务号“EnTA服务中心”【个人中心】进行查看和保存。</li>
                    <li>评测为纸笔化考试。评测用品（2B铅笔、橡皮、不带上网功能的计算器）须由测试者自行携带。考场不提供相关评测用品。</li>
                    <li>在正式评测开始30分钟前到达准考证上指定的考场，配合监考教师完成身份核对，并进行评测签到。</li>
                    <li>签到完成后在指定位置入座，并按考点要求将与评测无关物品放置在指定位置。</li>
                    <li>证件携带不齐全或不配合工作人员完成健康检测、身份核对、签到及拒绝将与评测无关物品放置在指定位置的测试者将不得进入考场，情节严重的将按违规处理。 </li>
                    <li>测试者须听从监考教师指令，在规定时间打开试卷、作答和停止作答，否则按违规处理。 </li>
                    <li>评测为闭卷，评测过程中测试者不得翻阅任何资料。</li>
                    <li>测试者在答题前，请按照准考证上的信息，将姓名和准考证号用2B铅笔填涂在答题卡上相应的位置。测试者需仔细填涂答题卡，若因测试者填涂答题卡不规范导致阅卷机无法识别，后果由测试者自行承担。</li>
                    <li>作答时，所有题目必须在答题卡上规定位置用2B铅笔填涂，修改时须用橡皮擦净 。</li>
                    <li>测试者在评测过程中出现违规违纪的行为，测试者将被取消继续参加评测的资格，评测成绩无效。如果在评测完成后，经EnTA评测中心核实，测试者存在违规违纪行为的，评测成绩将被取消。 </li>
                    <li>评测结束后，未经EnTA评测中心事先同意，测试者不得将试卷带出考场，不得将试卷内容泄露给第三方。</li>
                    <li>若测试者对成绩有异议，可在成绩发布后的7个工作日内在微信服务号“EnTA服务中心”【个人中心】提出成绩复查申请。EnTA评测中心审核同意后将再次对测试者的试卷进行阅卷机阅卷。复核结果将在成绩复查申请通过后的7个工作日内通过微信服务号“EnTA服务中心”反馈测试者本人。</li>
                    <li>EnTA职业能力评测仅为测试者提供相关专业的知识与技能证明，该评测结果证明可用作测试者在人才简历和档案中向企业雇主证明知识与技能，但无法作为相关应聘结果的保证。</li>
                </ul>
            </div>
        </div>
        <div class="paybtn">
          <div class="paybtminner" v-if="application.application_status==='待缴费'" @click="pay(application)">去缴费</div>
<!--          <div class="paybtminner" v-if="application.application_status==='待缴费'" @click="cancelApplication(application)" style="margin-top:10px">取消报名</div>-->
        </div>
    </div>
</template>

<script>
import { getApplication, createProductOrder, wx_pay, getSignPackage, cancelApplication } from '../api/test'
import { getUserId, getOpenId, getUserName} from '../utils/store'
export default {
  data () {
    return {
      application_id: this.$route.query.application_id,
      application: {
        application_channel: '',
        application_events: [],
        project: {},
        application_status: ''
      },
      user_name: '',
      loading: false,
      user_id: '',
      check: false
    }
  },
  created () {
    this.user_name = getUserName()
    this.user_id = getUserId()
    this.$nextTick(() => {
      this.fetchData()
    })
  },
  methods: {
    fetchData () {
      this.loading = true
      getApplication(this.application_id).then(res => {
        if (res.data.code === 0) {
          this.application = res.data.data
          this.loading = false
        }
      })
    },
    toHome () {
      this.$router.push('/')
    },
    async pay (application) {
      if (!this.check) {
        this.$message.error('请先勾选同意缴费须知')
        return
      }
      if (window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        const result = await this.createProductOrder(application)
        if (result.data.code === 0) {
          const order = result.data.data
          wx_pay(order._id.$id, this.user_id, getOpenId()).then(res => {
            this.initPay(res.data.data.timeStamp,
              res.data.data.nonceStr,
              res.data.data.package,
              res.data.data.signType,
              res.data.data.paySign)
          })
        }
      } else {
        this.$message.error('请至手机端支付')
      }
    },
    async createProductOrder (application) {
      return new Promise(resolve => {
        createProductOrder(this.user_id, application.project_id, '', '', application._id.$id).then(res => {
          resolve(res)
        })
      })
    },
    initPay (timestamp, nonceStr, package1, signType, paySign) {
      getSignPackage().then(res => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'chooseWXPay'
          ] // 必填，需要使用的JS接口列表
        })
      })
      wx.ready(() => {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: (res) => {
            // 支付成功后的回调函数
            this.$message({
              message: '缴费成功',
              type: 'success'
            })
            location.reload()
          }
        })
      })
    },
    cancelApplication (application) {
      cancelApplication(application._id.$id).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '取消成功',
            type: 'success'
          })
          this.$router.push('/applications')
        }
      })
    }
  }
}
</script>

<style>
.applicationtxt{
    margin-top: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 16px;
    color: #AEAEAE;
    padding-top:10px;
    padding-bottom: 10px;
    border-bottom:1px solid #E5E5E5
}
.paramtxt{
  border-bottom: 1px solid #AEAEAE;
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 15px;
  font-family: PingFang SC;

  line-height: 24px;
  color: #646464;
}
.maincontent{
    width: 86%;
    margin: 20px auto;
}
.paybtn{
  height: 100px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 60px;
}
.paybtminner{
  cursor: pointer;
  background: #4285F4;
  border: 1px solid #4285F4;
  border-radius: 4px;
  width: 90%;
  margin: 0 auto;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}
.checkbox{
    margin-top: 10px;
    font-size: 14px;
    /* display: flex;
    justify-content: center; */
}
.el-checkbox__input.is-checked+.el-checkbox__label{
    color: #4285F4!important;
}
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{
    background: #4285F4!important;
    border-color: #4285F4!important;
}
</style>
