<template>
    <div>
        <div style="position:relative;height:60px">
            <img :src="require('../../public/nav.png')" style="width:100%;height:60px">
            <div class="toptitle">实名认证</div>
            <img :src="require('../../public/back.png')"  class="back" @click="$router.back()"/>
        </div>
        <div class="topline" @click="toHome">
            <img :src="require('../../public/user.png')" style="width:20px"/>
            <span class="username">{{user_name}}</span>
        </div>
        <div class="userinfoblock">
            <div class="userinfotxt">手机号</div>
            <div>
                <input v-model="user.user_mobile" class="input" disabled />
            </div>
        </div>
        <div class="userinfoblock">
            <div class="userinfotxt">真实姓名</div>
            <div>
                <input v-model="user.real_name" class="input" disabled/>
            </div>
        </div>
        <div class="userinfoblock">
            <div class="userinfotxt">身份证号</div>
            <div>
                <input v-model="user.id_number" class="input" :disabled="user.info_auth_status && user.info_auth_status==='YES'"/>
            </div>
        </div>
        <div class="bottom">
            <div class="applicationbtn" @click="updatePersonalInfo" v-if="!user.info_auth_status">提交认证</div>
        </div>
    </div>
</template>

<script>
import { getUserId, getUserName } from '../utils/store'
import { updatePersonalInfo, getUser } from '../api/test'
export default {
  data () {
    return {
      user_name: '',
      user_id: '',
      user: {

      }
    }
  },
  created () {
    this.user_name = getUserName()
    this.user_id = getUserId()
    this.getUser()
  },
  methods: {
    updatePersonalInfo () {
      updatePersonalInfo(this.user.user_mobile, this.user.real_name, this.user.id_number, this.user_id).then(res => {
        if (res.data.code === 0) {
          this.$message({
            message: '认证成功',
            type: 'success'
          })
          this.$router.push('/home')
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    getUser () {
      getUser(this.user_id).then(res => {
        if (res.data.code === 0) {
          this.user = res.data.data
        }
      })
    },
    toHome () {
      this.$router.push('/home')
    }
  }
}
</script>

<style>
.input{
    border:none;
    outline:none;
    font-size: 14px;
    padding: 4px;
}

</style>
