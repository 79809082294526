<template>
  <div></div>
</template>

<script>
import { getOpenId, getStudentInfoByUnionid, getWXUserInfo } from '../api/test'
import { setOpenId, setUnionId, setUserId, setUserName, setAccountId } from '../utils/store'
import { Loading } from 'element-ui'

export default {
  data () {
    return {
      code: this.$route.query.code,
      route: this.$route.query.route,
      loadingInstance: undefined
    }
  },
  created () {
    if (this.code) {
      this.loadingInstance = Loading.service({ fullscreen: true, spinner: 'el-icon-loading' })
      getOpenId(this.code).then(res => {
        if (res.data.code === 0) {
          var open_id = res.data.data.openid
          var access_token = res.data.data.access_token
          setOpenId(res.data.data.openid)
          this.doGetWXUserInfo(open_id, access_token)
        } else {
          this.loadingInstance.close()
          this.$message.error('获取用户信息出错')
        }
      })
    } else {
      this.$message.error('未获取到code授权')
    }
  },
  methods: {
    doGetWXUserInfo (open_id, access_token) {
      getWXUserInfo(open_id, access_token).then((res) => {
        if (res.data.code === 0) {
          var result = res.data.data
          if (result.unionid) {
            var unionid = res.data.data.unionid
            setUnionId(unionid)
            this.doGetStudentInfoByUnionid(unionid)
          } else {
            this.loadingInstance.close()
            this.$message.error('发现你还没有关注我们的公众号，请先关注公众号！')
          }
        } else {
          this.loadingInstance.close()
          this.$message.error('获取用户信息出错')
        }
      })
    },
    doGetStudentInfoByUnionid (unionid) {
      getStudentInfoByUnionid(unionid).then(res => {
        this.loadingInstance.close()
        if (res.data.code === 0) {
          var student = res.data.data
          if (student) {
            setUserId(res.data.data._id.$id)
            setAccountId(res.data.data.account_id)
            var student_name = res.data.data.student_lastName + res.data.data.student_givenName
            // 学生的信息
            setUserName(student_name)
            this.$router.replace('/')
          } else {
            this.$router.replace('/' + this.route)
          }
        } else {
          this.$router.replace('/' + this.route)
        }
      })
    }
  }
}
</script>

<style>

</style>
