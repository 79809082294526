<template>
  <div></div>
</template>

<script>
// 入口2： 服务器的注册报名界面
import { setAccountId, setProjectCode } from '../../utils/store'

export default {
  name: 'fwh_register',
  mounted () {
    var account_id = this.$route.query.account_id
    var project_code = this.$route.query.project_code

    setAccountId(account_id)
    setProjectCode(project_code)

    const appId = 'wx8b69314d666b5158'
    const redirectUrl = 'https://fblc-gzh.reachable-edu.com/link?route=register'
    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + appId + '&redirect_uri=' + redirectUrl + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect&connect_redirect=1'
  }
}
</script>

<style scoped>

</style>
