import Cookies from 'js-cookie'

const USER_ID = 'fblc_user_id'
const USER_NAME = 'fblc_user_name'
const OPEN_ID = 'fblc_open_id'
const UNION_ID = 'fblc_union_id'
const ACCOUNT_ID = 'account_id'
const PROJECT_CODE = 'project_code'

export function setAccountId (account_id) {
  Cookies.set(ACCOUNT_ID, account_id)
}

export function setProjectCode (project_code) {
  Cookies.set(PROJECT_CODE, project_code)
}

export function getAccountId () {
  return Cookies.get(ACCOUNT_ID)
}

export function getProjectCode () {
  return Cookies.get(PROJECT_CODE)
}

export function setUnionId (union_id) {
  Cookies.set(UNION_ID, union_id)
}

export function getUnionId () {
  return Cookies.get(UNION_ID)
}

export function setUserId (user_id) {
  Cookies.set(USER_ID, user_id)
}

export function getUserId () {
  return Cookies.get(USER_ID)
}

export function removeUserId () {
  Cookies.remove(USER_ID)
}

export function setUserName (user_name) {
  Cookies.set(USER_NAME, user_name)
}

export function getUserName () {
  return Cookies.get(USER_NAME)
}

export function removeUserName () {
  Cookies.remove(USER_NAME)
}

export function setOpenId (open_id) {
  Cookies.set(OPEN_ID, open_id)
}

export function getOpenId () {
  return Cookies.get(OPEN_ID)
}

export function removeOpenId () {
  Cookies.remove(OPEN_ID)
}

export function removeUnionId () {
  Cookies.remove(UNION_ID)
}
