<template>
  <div class="container">
    <div style="height: 60px;background-color: #136AFB;text-align: center">
      <p style="margin: 0;padding: 0;color: white;font-size: 20px;line-height: 60px">注册</p>
    </div>
    <div class="form" style="margin-top: 10px">
      <el-form :model="register_form" :rules="rules" ref="register_form">
        <el-form-item label="注册项目">
          <el-input v-model="project_info.project_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="注册单位名称">
          <el-input v-model="account_info.account_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓氏" prop="student_lastName">
          <el-input v-model="register_form.student_lastName" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="名字" prop="student_givenName">
          <el-input v-model="register_form.student_givenName" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="姓氏拼音" prop="student_lastName_pinyin">
          <el-input v-model="register_form.student_lastName_pinyin" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="名字拼音" prop="student_givenName_pinyin">
          <el-input v-model="register_form.student_givenName_pinyin" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="student_gender">
          <el-select v-model="register_form.student_gender" placeholder="请选择">
            <el-option v-for="item in genders" :key="item" :value="item" :label="item"/>
          </el-select>
        </el-form-item>
        <el-form-item label="年级(预计高中毕业年份)" prop="student_graduation">
          <el-select v-model="register_form.student_graduation">
            <el-option v-for="item in graduations" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="student_mobile">
          <el-input v-model="register_form.student_mobile" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="邮箱(将作为账户登录标识)" prop="student_email">
          <el-input v-model="register_form.student_email" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="监护人手机" prop="student_guardian_first_mobile">
          <el-input v-model="register_form.student_guardian_first_mobile" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="监护人邮箱" prop="student_guardian_first_email">
          <el-input v-model="register_form.student_guardian_first_email" placehoder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="选择子项目" prop="application_event">
          <el-select v-model="register_form.application_event" placeholder="请选择">
            <el-option v-for="item in childProjects" :key="item" :value="item" :label="item"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div>
        <p class="note">
          注册须知：你的邮箱将作为账户唯一标识符。若没有账号，系统将自动为你创建账号；若有账号，系统将自动为你在原账号下创建注册信息。此链接为每个注册项目和注册单位专属，请确认你的注册项目和注册单位是否正确。新注册账户将被要求修改个人密码。注册提交后，将跳转进入项目缴费流程。你也可选择稍后登录资源中心（小程序或网页版）再完成缴费。
          联系我们：发邮件至contact@fblc-china.org 或 在资源中心发送消息
          访问官网：www.fblc-china.org</p>
      </div>

    </div>
    <div style="margin-bottom: 10px;margin-top: 10px">
      <div class="applicationbtn" @click="submitRegister('register_form')">提交注册</div>
    </div>
  </div>
</template>

<script>

import { addNewApplication, getAccount, getProject } from '../../api/test'
import {
  getAccountId,
  getProjectCode,
  getUnionId,
  setAccountId,
  setProjectCode,
  setUnionId,
  setUserId,
  setUserName
} from '../../utils/store'

export default {
  name: 'register',
  data () {
    return {
      account_info: {},
      project_info: {},
      childProjects: [],
      genders: ['男', '女'],
      graduations: ['2020', '2021', '2022', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030'],
      register_form: {
        account_id: '',
        project_code: '',
        unionid: '',
        student_lastName: '',
        student_givenName: '',
        student_lastName_pinyin: '',
        student_givenName_pinyin: '',
        student_gender: '',
        student_graduation: '',
        student_mobile: '',
        student_email: '',
        student_guardian_first_mobile: '',
        student_guardian_first_email: '',
        application_event: ''
      },
      rules: {
        student_lastName: [
          { required: true, message: '请输入你的姓氏', trigger: 'blur' }
        ],
        student_givenName: [
          { required: true, message: '请输入你的名字', trigger: 'blur' }
        ],
        student_lastName_pinyin: [
          { required: true, message: '请输入你的姓氏拼音', trigger: 'blur' }
        ],
        student_givenName_pinyin: [
          { required: true, message: '请输入你的名字拼音', trigger: 'blur' }
        ],
        student_gender: [
          { required: true, message: '请选择你的性别', trigger: 'blur' }
        ],
        student_graduation: [
          { required: true, message: '请选择你的年级', trigger: 'blur' }
        ],
        student_mobile: [
          { required: true, message: '请输入你的手机号码', trigger: 'blur' }
        ],
        student_email: [
          { required: true, message: '请输入你的邮箱', trigger: 'blur' }
        ],
        student_guardian_first_mobile: [
          { required: true, message: '请输入监护人手机号码', trigger: 'blur' }
        ],
        student_guardian_first_email: [
          { required: true, message: '请输入监护人邮箱', trigger: 'blur' }
        ],
        application_event: [
          { required: true, message: '请选择子项目', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    // test
    // setUnionId('oD3ql0ahOWwhMTrvOlLoEnimE0v0')
    // setProjectCode('21-22FBLC')
    // setAccountId('5be93f80a4640b531d8b462f')

    this.register_form.unionid = getUnionId()
    this.register_form.project_code = getProjectCode()
    this.register_form.account_id = getAccountId()
    this.fetchData()
  },
  methods: {
    fetchData () {
      getAccount(getAccountId()).then((res) => {
        this.account_info = res.data.data
      })
      getProject(getProjectCode()).then((res) => {
        this.project_info = res.data.data
        this.childProjects = this.project_info.project_events
      })
    },
    submitRegister (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          addNewApplication(this.register_form).then((res) => {
            var result = res.data
            var code = result.code
            if (code === 0) {
              const student_id = result.data._id.id
              setUserId(student_id)
              const user_name = this.register_form.student_lastName + this.register_form.student_givenName
              setUserName(user_name)
              this.$message({
                message: '报名成功',
                type: 'success'
              })
              this.$router.replace('/')
            } else {
              this.$message({
                message: res.data.msg,
                type: 'error'
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped>

.container {
  height: 100vh;
  background-color: white;
}

.form {
  width: 80%;
  margin: 0 auto;
}

.note {
  font-size: 12px;
  color: gray;
}

</style>
